import 'slick-carousel';

const $slider = $('.js-slider .slider__slides');

$slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    arrows: false,
    dots: true,
    fade: true,
    speed: 1200,
    infinite: true,
    cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
    touchThreshold: 100

});

const $slider__for = $('.slider__for');
const $slider__nav = $('.slider__nav');

$slider__for.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplaySpeed: 3000,
    // autoplay: true,
    asNavFor: '.slider__nav'
});
$slider__nav.slick({
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.slider__for',
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    infinite: false,
    arrows: false,
    responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                dots: false,
                arrows: false,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                dots: false,
                arrows: false,
            }
        },
        {
            breakpoint: 470,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: false,
                arrows: false,
            }
        }
    ]
});