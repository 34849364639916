/*
 * Polyfills
 */
import "./polyfills/object-fit";

/*
 * GSAP
 */
// import "./gsap/gsap.min.js";
// import "./gsap/gsap-shockingly-green/minified/MorphSVGPlugin.min.js";
// import "./gsap/gsap-shockingly-green/minified/ScrollTrigger.min.js";


/*
 * Modules
 */
import "./modules/header";
import "./modules/equalize";
import "./modules/slider";
import "./modules/tiles";
import "./modules/nav";
import "./modules/iframe-magnific";
import "./modules/filter";
import "./modules/parallax-custom";
import "./modules/aos-animations";